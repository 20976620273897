import React, { FunctionComponent } from 'react';

const Application: FunctionComponent = () => {
    return (
        <div>
            Application
        </div>
    );
}

export {
    Application,
}